import { ImagePositionOptions } from '../../../../types/types';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import settingsParams from '../../settingsParams';
import { getSettingsValidValue } from '../../hooks/useLayoutPBPSettings';

export const useInvertInfoPosition = () => {
  const settings = useSettings();
  const { isRTL } = useEnvironment();
  return (
    getSettingsValidValue(settings.get(settingsParams.serviceImagePosition)) ===
    (isRTL ? ImagePositionOptions.LEFT : ImagePositionOptions.RIGHT)
  );
};
