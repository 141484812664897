import stylesParams, {
  buildStringStyleParams,
  getStringStyleParamsValue,
} from '../stylesParams';
import settingsParams, {
  uninitializedVisibilitySettingsFalseValue,
  uninitializedVisibilitySettingsTrueValue,
} from '../settingsParams';
import {
  useSettingsAdapter,
  useStylesAdapter,
} from '../SharedSettings/adapters/SettingsAdapters';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { AlignmentOptions, ImagePositionOptions } from '../../../types/types';

export const getSettingsValidValue = (settingsValue: string) => {
  const stringArray = settingsValue.split('invalid');
  return stringArray.length > 1 ? stringArray[1].trim() : stringArray[0];
};

export const isSettingsValueValid = (settingsValue: string) => {
  return settingsValue.indexOf('invalid') === -1;
};

export const getBooleanSettingsValidValue = (settingsValue: boolean) => {
  if (typeof settingsValue === 'boolean') {
    return settingsValue;
  }
  return settingsValue === uninitializedVisibilitySettingsTrueValue;
};

export function useLayoutPBPSettings() {
  const { experiments } = useExperiments();
  const settings = useSettingsAdapter();
  const styles = useStylesAdapter();
  const layoutPbpEnabled = experiments.enabled(
    'specs.bookings.layoutStylePerBreakPoint',
  );

  return {
    alternateImageGetter: () => {
      const settingsValue = settings.get(
        settingsParams.alternateImageInfoSides,
      );
      if (typeof settingsValue === 'boolean') {
        return settingsValue;
      }

      if (layoutPbpEnabled) {
        return styles.get(stylesParams.alternateImageInfoSides);
      }

      return settingsValue === uninitializedVisibilitySettingsTrueValue;
    },
    alternateImageSetter: (value: any) => {
      if (layoutPbpEnabled) {
        settings.set(
          settingsParams.alternateImageInfoSides,
          value
            ? uninitializedVisibilitySettingsTrueValue
            : uninitializedVisibilitySettingsFalseValue,
        );
        styles.set(stylesParams.alternateImageInfoSides, value);
      } else {
        settings.set(settingsParams.alternateImageInfoSides, value);
      }
    },
    imagePositionStyleGetter: () => {
      const settingsValue = settings.get(settingsParams.serviceImagePosition);
      if (isSettingsValueValid(settingsValue)) {
        return settingsValue;
      }

      if (layoutPbpEnabled) {
        return styles.get(stylesParams.isServiceImageOnRight)
          ? ImagePositionOptions.RIGHT
          : ImagePositionOptions.LEFT;
      }

      return settingsValue === ImagePositionOptions.INVALID_RIGHT
        ? ImagePositionOptions.RIGHT
        : ImagePositionOptions.LEFT;
    },
    imagePositionStyleSetter: (value: ImagePositionOptions) => {
      if (layoutPbpEnabled) {
        settings.set(
          settingsParams.serviceImagePosition,
          value === ImagePositionOptions.RIGHT
            ? ImagePositionOptions.INVALID_RIGHT
            : ImagePositionOptions.INVALID_LEFT,
        );
        styles.set(
          stylesParams.isServiceImageOnRight,
          value === ImagePositionOptions.RIGHT ? true : false,
        );
      } else {
        settings.set(settingsParams.serviceImagePosition, value);
      }
    },
    serviceTextAlignmentSetter: (value: AlignmentOptions) => {
      if (experiments.enabled('specs.bookings.layoutStylePerBreakPoint')) {
        styles.set(
          stylesParams.serviceTextAlignment,
          buildStringStyleParams(value),
        );
      } else {
        settings.set(settingsParams.textAlignment, value);
      }
    },
    serviceTextAlignmentGetter: () => {
      if (experiments.enabled('specs.bookings.layoutStylePerBreakPoint')) {
        return getStringStyleParamsValue(
          styles.get(stylesParams.serviceTextAlignment),
        ) as AlignmentOptions;
      }
      return settings.get(settingsParams.textAlignment);
    },
    titleAlignemntGetter: (): AlignmentOptions => {
      if (layoutPbpEnabled) {
        return getStringStyleParamsValue(
          styles.get(stylesParams.titleAlignment),
        ) as AlignmentOptions;
      }
      return settings.get(settingsParams.titleAlignment);
    },
    titleAlignmentSetter: (value: AlignmentOptions) => {
      if (layoutPbpEnabled) {
        styles.set(stylesParams.titleAlignment, buildStringStyleParams(value));
      } else {
        settings.set(settingsParams.titleAlignment, value);
      }
    },
  };
}
