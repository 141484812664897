import React from 'react';
import { SettingsTab } from '../../../../types/types';
import { BOLinkName } from '../settingsConsts';

export interface ISettingsTabsContext {
  currentTabId: SettingsTab;
  setCurrentTabId: (settingsTab: SettingsTab) => void;
  openBackOfficeServices: (linkName: BOLinkName) => any;
  navigateToBookCheckout: () => any;
  openBackOfficeAddService: (linkName: BOLinkName) => any;
  isBookingCheckoutIsInstalled?: boolean;
}

export const SettingsTabsContext =
  React.createContext<ISettingsTabsContext | null>(null);
export const SettingsTabsContextProvider = SettingsTabsContext.Provider;

export const useSettingsTabContext = () => {
  const context = React.useContext(SettingsTabsContext);

  if (!context) {
    throw new Error('useSettingsTabContext must be used inside a provider');
  }

  return context;
};
